<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Reportes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">Consultas</li>
                  <li class="breadcrumb-item active">Reportes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card-body p-5">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="input-group">
                    <h1 class="m-0 text-dark text-center">
                      Tablero de Reportes
                    </h1>
                    <div class="input-group-append"></div>
                  </div>
                  <div class="btn-group float"></div>
                </div>
              </div>
              <div class="card-body">
                <div class="row clearfix">
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-general"
                        v-if="
                          $store.getters.can(
                            'tif.reportes.exportReporteGeneralOperativo'
                          )
                        "
                        @click="
                          limpiarModal('TifReporteGeneralOperativoExport')
                        "
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-file-alt fa-3x"
                              style="color: #31c177"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>General Operativo.</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-liquidaciones"
                        v-if="
                          $store.getters.can('tif.reportes.exportLiquidaciones')
                        "
                        @click="limpiarModal('TifReporteLiquidaciones')"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-donate fa-3x"
                              style="color: #00013a"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Consolidado Turnos TIF</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TifReporteGeneralOperativoExport
            ref="TifReporteGeneralOperativoExport"
          />
          <TifReporteLiquidaciones ref="TifReporteLiquidaciones" />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import TifReporteGeneralOperativoExport from "./TifReporteGeneralOperativoExport.vue";
import TifReporteLiquidaciones from "./TifReporteLiquidaciones.vue";
/* import vSelect from "vue-select";
import Loading from "../../../../components/Loading"; */
export default {
  name: "TifReporteIndex",
  components: {
    /* /*     Loading,
    vSelect, */
    TifReporteGeneralOperativoExport,
    TifReporteLiquidaciones,
  },
  data() {
    return {
      cargando: false,
      guia: [],
      sitio_origen: {},
      sitio_destino: {},
      form: {
        fecha_inicial: "",
        hora_inicial: "00:00:00",
        fecha_final: "",
        hora_final: "23:59:00",
      },
      filtros: {
        n_guia: null,
        producto_liquido_id: null,
        cedula_conductor: null,
        transportadora_id: null,
        sitio_origen_id: null,
        sitio_destino_id: null,
        placa: null,
      },
      listasForms: {
        productos_liquidos: [],
        sitiosOrigenes: [],
        sitiosDestinos: [],
        empresas: [],
      },
    };
  },
  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.form.fecha_inicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.form.fecha_inicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.form.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        var fecha_menor = new Date(this.form.fecha_inicial);
        var fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        fecha_menor = new Date(this.form.fecha_inicial).getTime();
        fecha_mayor = new Date(this.form.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_final) {
        var hoy = new Date();
        const fecha_final = new Date(this.form.fecha_final);
        fecha_final.setDate(fecha_final.getDate());
        if (fecha_final >= hoy) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
    getSelectSitioOrigen() {
      this.filtros.sitio_origen = {};
      this.filtros.sitio_origen_id = null;
      if (this.sitio_origen) {
        this.filtros.sitio_origen = this.sitio_origen;
        this.filtros.sitio_origen_id = this.sitio_origen.id;
      }
    },

    getSelectSitioDestino() {
      this.filtros.sitio_destino = {};
      this.filtros.sitio_destino_id = null;
      if (this.sitio_destino) {
        this.filtros.sitio_destino = this.sitio_destino;
        this.filtros.sitio_destino_id = this.sitio_destino.id;
      }
    },
    limpiarModal(reporte) {
      switch (reporte) {
        case "TifReporteGeneralOperativoExport":
          this.$refs.TifReporteGeneralOperativoExport.limpiarModal();
          break;

        default:
          break;
      }
    },
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/guias/exportGeneral",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
